import axios from 'axios';

let conected = window.navigator.onLine;

window.addEventListener('online', function () {
  conected = true;
});

window.addEventListener('offline', function () {
  conected = false;
});

const STAGE = {
  development: {
    url: '127.0.0.1:8000',
  },
  homologation: {
    url: 'https://hom.catalogo.leaderaplicativos.com.br/api/v1',
  },
  production: {
    url: 'https://hom.catalogo.leaderaplicativos.com.br/api/v1',
  },
};

const API_URL = STAGE.homologation.url;

export const chat_url = `wss://${STAGE.homologation.url}`;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!conected) {
      return Promise.reject('NO_INTERNET');
    }

    return Promise.reject(error);
  }
);

api.authURL = `${API_URL}/api-token-auth/web/`;
api.authRefreshURL = `${API_URL}/api-token-refresh/`;
api.url = API_URL;

export default api;

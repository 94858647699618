import api from '../API/config';

const ErrorsWhenValidatingEmailToRecoverPassword = {
  'USER_MATCHING_QUERY_DOES_NOT_EXIST.':
    'Não foi possível encontrar o seu usuário!',
};

const ErrorsWhenChangingPassword = {
  INVALID_CODE: 'O código está inválido!',
  'USER_MATCHING_QUERY_DOES_NOT_EXIST.':
    'Não foi possível encontrar o seu usuário!',
  CODE_NOT_EXIST: 'Verifique o código informado!',
};

class AuthService {
  async login(user) {
    const body = {
      email: user.email,
      password: user.password,
    };
    try {
      const { data } = await api.post(`${api.authURL}`, body, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      if (error === 'NO_INTERNET') {
        return Promise.reject(error, 'NO_INTERNET');
      }
      if (error?.response.data[0] === 'INVALID_USER') {
        return Promise.reject(error, 'INVALID_USER');
      }
      return null;
    }
  }

  async getNotificationsList(token) {
    try {
      const { data } = await api.get('notification/notify/list/', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      });

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async getTermsAndPolicy() {
    try {
      const { data } = await api.get('settings/terms-and-policy/', {});

      if (data) {
        return data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async confirmUserEmail(hashcod) {
    try {
      const { data } = await api.get(`user/register/confirm/${hashcod}/`, {});

      if (data && data?.detail) {
        return data;
      }
      return null;
    } catch (error) {
      if (
        error?.response?.data?.detail ===
        'You do not have permission to access this link.'
      ) {
        return 'INVALID_LINK';
      }

      return null;
    }
  }

  async validateEmailToRecoverPassword({ email }) {
    try {
      const { data } = await api.post('/user/adm/password-code/', { email });

      if (data) return data;

      return null;
    } catch (error) {
      if (error.response.data.ERROR) {
        const errorMessage = error.response.data.ERROR;

        return {
          error: ErrorsWhenValidatingEmailToRecoverPassword[errorMessage],
        };
      }

      return null;
    }
  }

  async changePassword({ email, code, password }) {
    try {
      const { data } = await api.post('/user/adm/change-password-code/', {
        email,
        code,
        password,
      });

      if (data) return data;

      return null;
    } catch (error) {
      if (error.response.data.ERROR) {
        const errorMessage = error.response.data.ERROR;

        return {
          error: ErrorsWhenChangingPassword[errorMessage],
        };
      }

      return null;
    }
  }
}

export default new AuthService();
